.discounts-summary {
    display: flex;
    flex-direction: column;
    list-style: none;
    text-align: left;
    padding: 0;
}

.discounts-summary.l-full {
    width: 100%;
}

.discounts-summary-item {
    display: flex;
}

.discounts-summary-item-description-value {
    display: flex;
    flex-grow: 1;
    margin-right: 10px;
}

.discounts-summary-item-total {
}

.discounts-summary-item-description {
    word-break: break-word;
    margin-right: 10px;
}

.discounts-summary.l-full {
    .discounts-summary-item-value, .discounts-summary-item-description {
        text-align: left;
    }
    .discounts-summary-item-total {
        text-align: right;
        text-wrap: nowrap;
    }
}

.shipping-address-selector {
    .shipping-address-container {
        margin-bottom: 1rem;
        background-color: $select-item-bg;
        padding: 1rem;
    }

    .shipping-address-container.selected {
        background-color: $select-item-selected-bg;
    }


}

.shipping-address-selector-query {
    margin-bottom: 1rem;
    padding: .5rem;
    border: none;
    border-bottom: 2px solid $light;
    display: block;
    background: transparent;
    width: 100%;
    transition: border-bottom-color .5s;

    &:active {
        border-bottom: 2px solid $primary;
    }

    &:focus {
        outline: none;
        border-bottom: 2px solid $primary;
    }

    &:hover {
        border-bottom: 2px solid $primary;
    }

}

//
// Form text
//

.form-text {
    color: $form-text-color;
    font-style: $form-text-font-style;
    font-weight: $form-text-font-weight;
    margin-top: $form-text-margin-top;

    @include font-size($form-text-font-size);
}

// SWIPER SLIDER

/* .swiper {
	width: 100%;
	height: auto;


	.swiper-wrapper {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		flex-direction: row;

		.swiper-slide {
			display: flex;
			justify-content: flex-start;
			flex-direction: column;
			align-items: center;
			text-align: center;
			img {
				width: 100%;
				height: 100%;
				@include media-breakpoint-up(sm) {
					width: 12rem;
					margin-bottom: 1rem;
				}
				@include media-breakpoint-up(md) {
					width: 15rem;
					margin-bottom: 1rem;
				}
			}
			.swiper-slide-title {
				font-weight: 900;
			}
			.swiper-slide-description {

		}

		.swiper-congrat {
			box-shadow: 0 0 .625rem rbga($black,.22);
			border-radius: .625rem;
			padding: 2rem 1rem 1rem 1rem;
			.swiper-slide-img {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				border-radius: 30rem;
				height: 3.25rem;
				width: 3.25rem;
				background-color: rgba($white,.27);
				margin-bottom: 1rem;
				img {
					width: 2rem;
					height: 2rem;
					margin: 0;
				}
			}
			.swiper-slide-name {
				color: $white;
				font-size: 1rem;
				line-height: 1.15;
				margin-bottom: 1rem;
				sub {
					color: $white;
				}
			}
			.swiper-slide-quantity {
				color: $white;
				font-weight: 900;
				font-size: 1.5rem;
				margin-bottom: -.5rem;
			}
			.swiper-slide-unit {
				color: $white;
				font-weight: 900;
				font-size: 1rem;
			}
		}
		.bg-1 {
			background-color: #10C177;
		}
		.bg-2 {
			background-color: #FDBF00;
		}
		.bg-3 {
			background-color: #FF6C6C;
		}
		.bg-4 {
			background-color: #76E2F8;
		}
		.bg-5 {
			background-color: #44D7B6;
		}
		.bg-6 {
			background-color: #FFD400;
		}
		.bg-7 {
			background-color: #2AD414;
		}
		.bg-8 {
			background-color: #7E5DF0;
		}
	}
	.swiper-pagination {
		position: relative;
		margin-top: 1rem;
	}
	.swiper-pagination-bullet {
		background: $primary;
	}

	@include media-breakpoint-up(sm) {
		margin-bottom: 2rem;
	}

} */
.swiper {
    height: 100%;
    width: 100%;
}

.swiper-slide {
    align-items: center;
    background: #FFFFFF;

    /* Center slide text vertically */
    display: flex;
    font-size: 18px;
    justify-content: center;
    text-align: center;
}

.swiper-slide img {
    display: block;
    height: 100%;
    object-fit: cover;
    object-fit: cover;
    width: 100%;
}

.swiper {
    height: 300px;
    list-style: none;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
    padding: 0;
    position: relative;
    width: 100%;
    z-index: 1;
}

.swiper-slide {
    background-position: center;
    background-size: cover;
    cursor: pointer;

    a {
        text-decoration: none;
    }
}

.swiper-product-detail-big {
    height: 80%;
    width: 100%;
}

.swiper-product-detail {
    box-sizing: border-box;
    height: 20%;
    margin-top: 1rem;
    padding: 10px 0;
}

.swiper-product-detail .swiper-slide {
    cursor: pointer;
    height: 100%;
    opacity: .2;
    transition: .1s;
    width: 25%;

    &:hover {
        opacity: 1;
    }
}

.swiper-product-detail .swiper-slide-thumb-active {
    opacity: 1;
}

.swiper-slide img {
    display: block;
    height: 100%;
    object-fit: cover;
    object-fit: cover;
    width: 100%;
}

span.swiper-pagination-bullet.swiper-pagination-bullet-active {
    background-color: $primary;
}

.swiper-pagination.swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: 0;
}

.swiper-pagination {
    bottom: 0 !important;
    margin-bottom: -2rem !important;
    margin-top: auto !important;
    margin-top: 2rem !important;
    position: relative !important;
}

.swiper-container-horizontal > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
    white-space: normal !important;
}

.swiper-button-next,
.swiper-button-prev {
    color: #FFFFFF !important;
}

.swiper-button-next {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E");
}

.swiper-button-prev {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E");
}

.swiper-button-next::after,
.swiper-button-prev::after {
    content: '' !important;
}

.swiper-product-type {
    overflow: hidden;
    padding-bottom: 2em;
}

.swiper-button-prev,
.swiper-button-next {
    margin: 0 1em;
    margin-top: -12em !important;
    top: auto !important;
}

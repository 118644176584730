.form-floating {
    position: relative;

    > .form-control,
    > .form-select {
        height: $form-floating-height;
        line-height: $form-floating-line-height;
    }

    > label {
        border: $input-border-width solid transparent; // Required for aligning label's text with the input as it affects inner box model
        height: 100%; // allow textareas
        left: 0;
        padding: $form-floating-padding-y $form-floating-padding-x;
        pointer-events: none;
        position: absolute;
        top: 0;
        transform-origin: 0 0;

        @include transition($form-floating-transition);
    }

    // stylelint-disable no-duplicate-selectors
    > .form-control {
        padding: $form-floating-padding-y $form-floating-padding-x;

        &::placeholder {
            color: transparent;
        }

        &:focus,
        &:not(:placeholder-shown) {
            padding-bottom: $form-floating-input-padding-b;
            padding-top: $form-floating-input-padding-t;
        }
        // Duplicated because `:-webkit-autofill` invalidates other selectors when grouped
        &:-webkit-autofill {
            padding-bottom: $form-floating-input-padding-b;
            padding-top: $form-floating-input-padding-t;
        }
    }

    > .form-select {
        padding-bottom: $form-floating-input-padding-b;
        padding-top: $form-floating-input-padding-t;
    }

    > .form-select {
        ~ label {
            opacity: unset;
            transform: none;
        }
    }

    > .form-control:not(:placeholder-shown),
    > .form-control:focus {
        ~ label {
            opacity: $form-floating-label-opacity;
            transform: $form-floating-label-transform;
        }
    }
    // Duplicated because `:-webkit-autofill` invalidates other selectors when grouped
    > .form-control:-webkit-autofill {
        ~ label {
            opacity: $form-floating-label-opacity;
            transform: $form-floating-label-transform;
        }
    }
    // stylelint-enable no-duplicate-selectors
}

//
// Stretched link
//

.stretched-link {
    &::#{$stretched-link-pseudo-element} {
        bottom: 0;
        content: '';
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        z-index: $stretched-link-z-index;
    }
}

.cart-payment-terms {
    .label {
        font-weight: bolder;
        margin-right: 5px;
    }
}

.cart-payment-terms-limit-price {
    font-weight: bold;
}

.attached-files-list {
    max-height: 15rem;
    overflow: auto;

    .attached-files-list-item-container {
        cursor: pointer;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: .5rem;
        word-break: break-word;

        &:hover {
            background-color: #C49D6833;
        }
    }
}

@import 'src/styles/reset';
@import 'src/styles/variables/all';
@import 'src/styles/components/all';
@import 'src/styles/bootstrap/bootstrap-grid.scss';
@import 'src/styles/bootstrap/bootstrap-reboot.scss';
@import 'src/styles/bootstrap/bootstrap.scss';


.no-items-loaded-message-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    flex-direction: column;

    > svg {
        width: 60px;
        height: 60px;
    }

    >p {
        opacity: 1;
        color: $medium;
        font-size: 14px;
        font-weight: $co-font-bold;
        letter-spacing: -0,05px;
        text-align: center;
        width: 200px;
    }
}

@media (max-width: 1700px) {
    .no-items-loaded-message-container {
        margin: 3rem 0;
    }
}

// ORDERS
.screen {
    margin-top: 100px;
}
.table-striped>tbody>tr:nth-of-type(odd) {
	background-color: rgba($dark,.0);
}

.noButtonAppearance button,
.noButtonAppearance button:hover,
.noButtonAppearance button:active,
.noButtonAppearance button:focus {
    background-color: transparent;
    border: 0;
    box-shadow: unset;
    padding: 0;
}

.iconDots {
    height: 1.25rem;
    margin-left: .5rem;
    margin-right: .5rem;
    margin-top: -.125rem;
    width: 1.25rem;
}

.dropstart .dropdown-toggle::before,
.dropdown-toggle::after {
    display: none !important;
}

.noButtonAppearance #dropdown-button-drop-end {
    opacity: .2;
    padding: 1rem 0;

    &:hover {
        opacity: 1;
    }
}

//
// Base styles
//

.alert {
    border: $alert-border-width solid transparent;
    margin-bottom: $alert-margin-bottom;
    padding: $alert-padding-y $alert-padding-x;
    position: relative;

    @include border-radius($alert-border-radius);
}

// Headings for larger alerts
.alert-heading {
    // Specified to prevent conflicts of changing $headings-color
    color: inherit;
}

// Provide class for links that match alerts
.alert-link {
    font-weight: $alert-link-font-weight;
}

// Dismissible alerts
//
// Expand the right padding and account for the close button's positioning.

.alert-dismissible {
    padding-right: $alert-dismissible-padding-r;

    // Adjust close link position
    .btn-close {
        padding: $alert-padding-y * 1.25 $alert-padding-x;
        position: absolute;
        right: 0;
        top: 0;
        z-index: $stretched-link-z-index + 1;
    }
}

// scss-docs-start alert-modifiers
// Generate contextual modifier classes for colorizing the alert.

@each $state, $value in $theme-colors {
    $alert-background: shift-color($value, $alert-bg-scale);
    $alert-border: shift-color($value, $alert-border-scale);
    $alert-color: shift-color($value, $alert-color-scale);

    @if (contrast-ratio($alert-background, $alert-color) < $min-contrast-ratio) {
        $alert-color: mix($value, color-contrast($alert-background), abs($alert-color-scale));
    }
    .alert-#{$state} {
        @include alert-variant($alert-background, $alert-border, $alert-color);
    }
}
// scss-docs-end alert-modifiers

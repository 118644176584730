// Credit: Nicolas Gallagher and SUIT CSS.

.ratio {
    position: relative;
    width: 100%;

    &::before {
        content: '';
        display: block;
        padding-top: var(--#{$variable-prefix}aspect-ratio);
    }

    > * {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }
}

@each $key, $ratio in $aspect-ratios {
    .ratio-#{$key} {
        --#{$variable-prefix}aspect-ratio: #{$ratio};
    }
}

footer {
    background-color: $dark;
    color: $white;
    padding: 2rem 1.25rem .875rem;
    width: 100%;

    @include media-breakpoint-up(xl) {
        bottom: 0;
        left: 0;
        z-index: -2;
    }

    a {
        color: #FFFFFF;
        cursor: pointer;

        &:hover {
            color: $primary;
            transition: color .3s ease-out;
        }
    }

    .logo-footer {
        @include media-breakpoint-down(md) {
            display: flex;
            justify-content: center;
        }
    }

    .list-unstyled {
        .small {
            font-weight: 100;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .locations-container {
        display: grid;
        grid-gap: 2rem;
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    .location {
        padding-bottom: 1rem;

        h6 {
            color: $medium;
            font-size: .75rem;
            text-transform: uppercase;
        }

        p {
            color: $light;
            font-size: .75rem;
            font-weight: 100;
        }
    }

    .footer-legal {
        text-align: left;

        img {
            margin-bottom: 2rem;
            width: 10rem;
        }

        @include media-breakpoint-down(md) {
            text-align: center;
        }
    }

    .footer-contact {
        text-align: right;

        @include media-breakpoint-down(md) {
            text-align: center;
        }
    }

    .footer-location {
        text-align: left;

        @include media-breakpoint-down(md) {
            text-align: center;
        }
    }

    .border-top {
        border-top: .02rem solid rgba($medium, .3) !important;

        @include media-breakpoint-down(md) {
            align-items: center;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
        }
    }

    .bottom-footer {
        align-items: center;
        border-top: .02rem solid rgba($medium, .3) !important;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        padding-top: 1.5rem;

        @include media-breakpoint-down(lg) {
            flex-direction: column;
        }
    }

    .social-links {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-end;

        li {
            font-size: .875em;
            font-weight: bold;

            a {
                padding: 1em;
            }
        }

        @include media-breakpoint-down(md) {
            justify-content: center;
            text-align: center;
        }
    }

    @include media-breakpoint-down(md) {
        text-align: center;
    }
}

.lan-icon {
    display: initial;
    height: 1.3rem;
    margin-right: .5rem;
    margin-top: -.5rem;
}


.loading-orders-list {
    >tbody{
        tr{
            td{
            vertical-align: middle;
            }
        }
    }
}

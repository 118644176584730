// FILTER OFFCANVAS
.filter-footer {
    align-items: center;
    background-color: $white;
    box-shadow: 0 0 1rem rgba($dark, .2);
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 1rem;
    position: sticky;
    position: sticky;
}

.filter-tag-container {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin-bottom: 2rem;

    .filter-tag {
        align-items: center;
        background-color: #F0F0F0;
        border-radius: 100px;
        color: gray;
        display: flex;
        flex-direction: row;
        font-size: .875rem;
        margin-bottom: .5rem;
        margin-left: .5rem;
        padding: .25rem .75rem;
        width: max-content;
        width: max-content;
        width: max-content;

        svg {
            height: 1rem;
            margin-left: 1rem;
            width: 1rem;
        }
    }
}

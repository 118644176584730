// transparent background and border properties included for button version.
// iOS requires the button element instead of an anchor tag.
// If you want the anchor version, it requires `href="#"`.
// See https://developer.mozilla.org/en-US/docs/Web/Events/click#Safari_Mobile

.btn-close {
    background: transparent escape-svg($btn-close-bg) center / $btn-close-width auto no-repeat; // include transparent for button elements
    border: 0; // for button elements
    box-sizing: content-box;
    color: $btn-close-color;
    height: $btn-close-height;
    opacity: $btn-close-opacity;
    padding: $btn-close-padding-y $btn-close-padding-x;
    width: $btn-close-width;

    @include border-radius();

    // Override <a>'s hover style
    &:hover {
        color: $btn-close-color;
        opacity: $btn-close-hover-opacity;
        text-decoration: none;
    }

    &:focus {
        box-shadow: $btn-close-focus-shadow;
        opacity: $btn-close-focus-opacity;
        outline: 0;
    }

    &:disabled,
    &.disabled {
        opacity: $btn-close-disabled-opacity;
        pointer-events: none;
        user-select: none;
    }
}

.btn-close-white {
    filter: $btn-close-white-filter;
}

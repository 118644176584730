@import 'src/styles/variables/all';
@import 'src/styles/bootstrap/bootstrap-grid.scss';

.shipping-address-selector-body-container {
    display: flex;
    flex-direction: column;

    .shipping-address-selector-body-text {
        margin-bottom: 2rem;
    }

    .shipping-address-selector-form-container {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        .shipping-address-selector-form-item {
            display: flex;
            flex-direction: column;

            .shipping-address-selector-form-item-info {
                font-size: 12px;
                font-weight: $font-weight-bold;
                color: $danger;
                padding: .5rem;
            }
        }
    }
}

hr {
    height: 2px;
    margin-bottom: 1rem;
    width: 100%;
}

.shipping-address-selector-footer-container {
    display: flex;
    flex-direction: row;

    :first-child {
        margin-right: 2rem;
    }
}


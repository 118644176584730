// BUTTONS
.btn {
    background-color: $primary;
    border: .0836rem;
    border-radius: 0;
    color: $white;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: .02rem;
    padding: .5rem 1.5rem;
    transition: .15s;

    &:hover,
    &:active,
    &:focus {
        background-color: darken($primary, 8%);
        color: $white;
    }
}

.btn-mini {
    transform: scale(.85);
}

.btn-secondary {
    background-color: $secondary;
}

.btn-primary-outline {
    background-color: rgba($white, 0);
    border: .0836rem solid rgba($primary, 1);
    color: $primary;
}

.btn-link,
a:not([href]):not([tabindex]) {
    background-color: transparent;
    color: $primary;
    cursor: pointer;

    &:hover {
        background-color: transparent;
        color: darken($primary, 5%);
    }

    &:focus {
        background-color: transparent;
        border: 0;
        color: darken($primary, 16%);
    }
}

.btn-sso {
    align-items: center;
    border-radius: .625rem;
    box-shadow: 0 0 1.25rem rgba($black, .12);
    cursor: pointer;
    display: grid;
    grid-template-columns: 1.5rem auto;
    max-width: 18rem;
    padding-left: 1rem;
    text-align: center;
    text-transform: none;
    width: 100%;

    .btn-label {
        margin-right: 1rem;

        img {
            height: 1.5rem;
            width: 1.5rem;
        }
    }
}

.btn-apple {
    background-color: rgb(0 0 0 / 92%) !important;

    &:hover,
    &:active,
    &:focus {
        color: $white;
    }
}

.btn-fb {
    background-color: #166FE5 !important;

    &:hover,
    &:active,
    &:focus {
        color: $white;
    }
}

.btn-google {
    background-color: $white !important;
    color: $black;

    &:hover,
    &:active,
    &:focus {
        color: $black;
    }
}

.btn-logout {
    background-color: rgba($primary, .1);
    box-shadow: none;
    padding: .5rem 1.5rem .5rem 1rem;
    position: absolute;
    right: 1rem;
    top: 1rem;
    width: auto;

    @include media-breakpoint-down(sm) {
        padding: 1rem;
    }

    .btn-logout-icon {
        img {
            opacity: .7;
        }
    }

    .btn-logout-text {
        color: rgba($primary, .7);
        font-weight: 700;
        margin-top: .25rem;
        text-transform: none;

        @include media-breakpoint-down(sm) {
            display: none;
        }
    }

    &:hover,
    &:active,
    &:focus {
        .btn-logout-icon {
            img {
                opacity: 1;
            }
        }

        .btn-logout-text {
            color: rgba($primary, 1);
        }
    }
}

.btn-pdf {
    background-color: rgba($success, 0);
    box-shadow: none;
    max-width: none;
    padding: .5rem 1.5rem;
    width: 100%;

    .btn-pdf-text {
        color: rgba($success, .7);
        font-weight: 700;
        text-transform: none;
    }
}

.download-app {
    margin-top: 1rem;

    img {
        height: 2rem;
    }
}

a {
    color: $primary;
    font-weight: 500;
    text-decoration: underline;
    transition: all .3s ease;
    transition: all .3s ease;

    &:hover {
        color: darken($primary, 5%);
    }
}

.anchor-div {
    cursor: pointer;
}

a {
    color: $primary;
    font-weight: 500;
    text-decoration: underline;
    transition: all .3s ease;
    transition: all .3s ease;

    &:hover {
        color: darken($primary, 5%);
    }
}

.dropup > .dropdown-toggle,
.show > .btn-success.dropdown-toggle {
    color: $primary;
}

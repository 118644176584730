.toast {
    background-clip: padding-box;
    background-color: $toast-background-color;
    border: $toast-border-width solid $toast-border-color;
    box-shadow: $toast-box-shadow;
    color: $toast-color;
    max-width: 100%;
    pointer-events: auto;
    width: $toast-max-width;

    @include font-size($toast-font-size);
    @include border-radius($toast-border-radius);

    &.showing {
        opacity: 0;
    }

    &:not(.show) {
        display: none;
    }
}

.toast-container {
    max-width: 100%;
    pointer-events: none;
    width: max-content;

    > :not(:last-child) {
        margin-bottom: $toast-spacing;
    }
}

.toast-header {
    align-items: center;
    background-clip: padding-box;
    background-color: $toast-header-background-color;
    border-bottom: $toast-border-width solid $toast-header-border-color;
    color: $toast-header-color;
    display: flex;
    padding: $toast-padding-y $toast-padding-x;

    @include border-top-radius(subtract($toast-border-radius, $toast-border-width));

    .btn-close {
        margin-left: $toast-padding-x;
        margin-right: $toast-padding-x * -.5;
    }
}

.toast-body {
    padding: $toast-padding-x; // apply to both vertical and horizontal
    word-wrap: break-word;
}

.navbar {
    background-color: $white;
    // box-shadow: 1rem 1rem 1rem rgba($dark,.05);
    border-bottom: .031rem solid rgba($dark, .05);
    max-height: 5.375rem;
    padding: 1rem 1.25rem;

    @include media-breakpoint-down(lg) {
        padding: .5rem;
    }

    .navbar-toggler {
        left: .875rem;
        position: absolute;
        top: 1.5rem;

        @include media-breakpoint-down(sm) {
            top: 1rem;
        }
    }
}

.navbar-brand {
    margin-left: auto;
    margin-right: auto;
    opacity: 1;

    img {
        max-height: 2.5rem;
    }

    @include media-breakpoint-down(sm) {
        img {
            max-height: 1.75rem;
        }
    }
}

.navbar-nav {
    // justify-content: flex-end;
    align-items: center;
    text-align: center;
    width: 100%;

    @include media-breakpoint-down(lg) {
        background-color: $white;
        box-shadow: 0 2rem 2rem rgba($dark, .2);
        left: 0;
        padding-bottom: 2rem;
        padding-top: 1rem;
        position: absolute;
        right: 0;
        top: 4.25rem;
    }

    @include media-breakpoint-up(lg) {
        justify-content: flex-end;
        margin-right: 5rem;
    }

    .nav-item {
        margin-left: 1rem;
        margin-right: 1rem;
    }

    .nav-link {
        padding-bottom: 1rem;
        padding-top: 1rem;
    }
}

.icons-nav {
    column-gap: 1rem;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    list-style: none;
    position: absolute;
    right: 2rem;
    top: 22px;

    @include media-breakpoint-down(sm) {
        right: 1.25rem;
        top: 1.25rem;
    }
}

// TABS
.nav-pills {
    .nav-item {
        text-align: center;

        .nav-link {
            background-color: $white;
            border-bottom: .25rem solid rgba($primary, 0);
            border-radius: 0;
            color: $medium;

            &.active,
            &:focus {
                border-bottom: .25rem solid $primary !important;
                color: $primary !important;
            }

            &:hover {
                border-bottom: .25rem solid rgba($primary, 0);
                color: $dark;
            }
        }
    }
}

.badge-timer {
    color: $dark;
    left: 50%;
    top: 2.5rem !important;
}

.navbar-icons-right {
    font-size: .65em;
    font-weight: 300;
}

.button-logout {
    color: #C49D68;
    font-weight: 500;
    padding-bottom: 1rem;
    padding-top: 1rem;
}

.shipping-address-container {
    display: flex;
    justify-content: space-between;

    .shipping-address-info {
        flex: 9;
    }

    .shipping-address-actions {
        flex: 1;
        margin-left: auto;

        .btn-icon {
            background: transparent;
            border: none;
            cursor: pointer;
            margin: 0 .5rem;
        }
    }
}

// FORMS

form {
    width: 100%;

    .form-group {
        margin-bottom: 2rem;
    }

    label {
        // font-size: .75rem;
        margin-bottom: 0;
    }

    .form-control {
        background-color: rgba($white, 0);
        border: none;
        border-bottom: .125rem solid $light;
        border-radius: 0;
        height: 2.25rem;
        padding-left: 0;

        &:focus,
        &:active {
            background-color: rgba($white, 0);
            border: 0;
            border-bottom: .125rem solid $primary;
            box-shadow: none;
        }
    }

    .form-control[disabled] {
        background-color: $gray-200;
        border-bottom: none;
    }

    .input-required {
        color: $danger;
    }
}

input::placeholder {
    color: $light !important;
}

.form-check-input:checked {
    background-color: $primary;
    border-color: $primary;
}

.form-check {
    margin-left: 1rem;
    padding-left: inherit;
}

.check-message {
    background-color: rgba($primary, .035);
    border-radius: .875rem;
    font-size: .875rem;
    margin-left: .75rem;
    margin-top: .5rem;
    padding: .75rem .5rem .5rem;
}

.form-check-input:focus {
    border-color: lighten($primary, 10%);
    outline: 0;
}

.form-row-import {
    background-color: #ECECEC;
    border-radius: 1rem;
    margin-left: auto;
    margin-right: auto;
    max-width: 30rem;
    padding: 1rem 1rem 0;

    .form-group {
        margin-bottom: 1rem;
    }

    .form-control {
        color: rgba($dark, .7);
        font-size: 2rem;
        padding-left: 2rem;
    }
}

.form-floating {
    .form-control {
        padding: 1rem .75rem 1rem .1rem;
    }

    label {
        padding-left: 0;
    }
}

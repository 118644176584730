// Swiper Slider
.swiper-container-hero {
    background-color: white;
    height: 100vh;
    left: 0;
    margin: 0;
    min-height: 46rem;
    position: absolute !important;
    top: 0;
    width: 100vw;
    z-index: -1;

    &::after {
        background-color: rgba($dark, .6);
        content: '';
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 1;
    }
}

.swiper-wrapper {
    box-sizing: content-box;
    display: flex;
    height: 100%;
    position: relative;
    transition-property: transform;
    width: 100%;
    z-index: 1;
}

.swiper-slide {
    align-items: center;
    display: flex;
    flex-shrink: 0;
    font-size: 18px;
    height: 100%;
    justify-content: center;
    margin: 0;
    position: relative;
    text-align: center;
    width: 100%;

    img,
    video {
        height: 100%;
        object-fit: cover;
        object-fit: cover;
        width: 100%;
    }
}

.swiper-pagination {
    margin-top: 1rem;
    position: relative !important;
}

.swiper-pagination-bullet-active {
    background-color: $primary;
}

.placeholder {
    background-color: currentColor;
    cursor: wait;
    display: inline-block;
    min-height: 1em;
    opacity: $placeholder-opacity-max;
    vertical-align: middle;

    &.btn::before {
        content: '';
        display: inline-block;
    }
}

// Sizing
.placeholder-xs {
    min-height: .6em;
}

.placeholder-sm {
    min-height: .8em;
}

.placeholder-lg {
    min-height: 1.2em;
}

// Animation
.placeholder-glow {
    .placeholder {
        animation: placeholder-glow 2s ease-in-out infinite;
    }
}

@keyframes placeholder-glow {
    50% {
        opacity: $placeholder-opacity-min;
    }
}

.placeholder-wave {
    animation: placeholder-wave 2s linear infinite;
    mask-image: linear-gradient(130deg, $black 55%, rgba(0, 0, 0, (1 - $placeholder-opacity-min)) 75%, $black 95%);
    mask-size: 200% 100%;
}

@keyframes placeholder-wave {
    100% {
        mask-position: -200% 0%;
    }
}

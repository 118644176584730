// Shared between modals and offcanvases
@mixin overlay-backdrop($zindex, $backdrop-bg, $backdrop-opacity) {
    background-color: $backdrop-bg;
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: $zindex;

    // Fade for backdrop
    &.fade {
        opacity: 0;
    }

    &.show {
        opacity: $backdrop-opacity;
    }
}

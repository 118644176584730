@import 'src/styles/reset';
@import 'src/styles/variables/all';
@import 'src/styles/components/all';
@import 'src/styles/bootstrap/bootstrap-grid.scss';
@import 'src/styles/bootstrap/bootstrap-reboot.scss';
@import 'src/styles/bootstrap/bootstrap.scss';


.loading-order-summary-card-container {
    background-color: $co-color-gray_light;;
    padding: 1rem;

    ul {
        color: $dark;
        display: flex;
        flex-direction: row;
        font-size: 1rem;
        gap: .5rem;
        justify-content: space-around;
        list-style: none;
        margin-bottom: 0;
        padding: 0;
        text-align: left;

        @include media-breakpoint-down(lg) {
            display: flex;
            flex-direction: column;
        }

        li {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: .5rem;

            @include media-breakpoint-down(lg) {
                flex-wrap: wrap;
                flex-direction: row;
                margin-bottom: 1rem;
                text-align: left;
            }

            p {
                margin-bottom: 0;
            }
        }
    }
}

@import 'src/styles/variables/all';
.summary-container {
    width: 100%;
    display: flex;
    flex-direction: column;

    .summary-header {
        display: flex;
        justify-content: space-between;
        margin-top: 1rem;
    }

    .summary-box {
        background-color: rgba($medium,.05);
        padding: 1rem 2rem;
        margin-bottom: 1rem;
        width: 100%;
        display: flex;
        justify-content: space-between;
        gap: 1rem;

        @include media-breakpoint-down(md) {
            display: flex;
            flex-direction: column;
        }

        .summary-box-items-container {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            gap: 1rem;

            .summary-box-item {
                display: flex;
                flex-direction: column;

                @include media-breakpoint-down(md) {
                    flex-direction: row;
                    margin-bottom: 1rem;
                    text-align: left;
                    justify-content: space-between;
                    flex-wrap: wrap;
                }

                .summary-box-item-title {
                    font-size: 16px;
                    font-weight: $co-font-bold;
                }

                .summary-box-item-body {
                    display: flex;
                }
            }
        }
    }
}

@import 'src/styles/reset';
@import 'src/styles/variables/all';
@import 'src/styles/components/all';
@import 'src/styles/bootstrap/bootstrap-grid.scss';
@import 'src/styles/bootstrap/bootstrap-reboot.scss';
@import 'src/styles/bootstrap/bootstrap.scss';

.orders-to-load-table {
    width: 100%;
    overflow: auto;

    thead {
        font-size: 12px;
    }

    tbody {
        tr {
            font-size: 14px;

            &.is-loaded {
                > td {
                    background-color: $orderLoadedRow;
                }
            }

            &.is-item-loaded {
                > td {
                    background-color: $orderItemLoadedRow;
                }
            }


            td {
                background-color: $white;

                .show-lines-button {
                    background-color: transparent;
                    border: 2px solid $loadingOrderActionButton;
                    border-radius: 60px;
                    display: flex;
                    align-items: center;

                    &:hover {
                        filter: brightness(1.1);
                    }

                    > svg {
                        width: 18px;
                        height: 18px;
                    }
                }

                .add-item-button {
                    border-radius: 5px;
                    border: none;
                    background-color: $loadingOrderActionButton;

                    &:hover {
                        filter: brightness(1.1);
                    }

                    &.primary {
                        background-color: $primary;
                    }
                }
            }
        }

        .order-to-load-info-row {
            font-weight: 900;
            font-size: 16px;

            > td {
                background-color: $stripe;
            }
        }

        .empty-row{
            > td {
                background-color: transparent;
            }
        }
    }
}


// scss-docs-start alert-variant-mixin
@mixin alert-variant($background, $border, $color) {
    border-color: $border;
    color: $color;

    @include gradient-bg($background);

    .alert-link {
        color: shade-color($color, 20%);
    }
}
// scss-docs-end alert-variant-mixin

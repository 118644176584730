@import '../variables/colors';

$banner-fg: $white;
$banner-bg: $secondary;

.page-banner {
    background-color: $banner-bg;
    color: $banner-fg;
    font-family: 'Jost*';
    font-size: 24px;
    font-weight: bold;
    line-height: 28px;
    padding: 22px;
    text-align: center;
    width: 100%;

}

.page-banner.danger {
    background-color: $tertiary;
}
.page-banner-link {
    color: $banner-fg;

    &:hover {
        color: $banner-fg;
    }
}

.screen-cart_payment_card {

}

.screen-container.l-modal {
    width: 420px;
    .header {
        text-align: center;
        margin-bottom: 15px;
        .section-title {
            font-size: 22px;
        }
    }
    .body{
        .action-button {
            width: 100%;
            margin-bottom: 10px;
        }
    }
}

.container-cart_payment_card {
    .header {
        .logo {
            margin-bottom: 15px;
            height: 24px;
            & svg {
                height: 24px;
            }
        }
        .amount {
            .total {
                font-size: 24px;
                font-weight: 900;
            }
            .tax {
                font-size: 12px;
            }
        }
    }

    .body{
        .StripeElement {
            margin-bottom: 25px;
        }
    }
}




@import 'src/styles/reset';
@import 'src/styles/variables/all';
@import 'src/styles/components/all';
@import 'src/styles/bootstrap/bootstrap-grid.scss';
@import 'src/styles/bootstrap/bootstrap-reboot.scss';
@import 'src/styles/bootstrap/bootstrap.scss';

.section-header-loading-order-detail-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 2rem;
    column-gap: 10px;
    row-gap: 10px;

    .section-header-loading-order-title-container{
        display: flex;
        align-items: center;
        column-gap: 30px;
        flex-wrap: wrap;
    }

    .section-header-loading-order-actions-container{
        display: flex;
        justify-content: center;
        gap: 10px;
        flex-wrap: wrap;
    }
}

.custom-type-ahead-basic {
    margin-bottom: 1rem;

    .rbt-aux {
        .rbt-loader,
        spinner-border,
        spinner-border-sm {
            display: none;
        }

        >button {
            background-color: transparent;
            border: none;
            bottom: 0;
            cursor: pointer;
            position: absolute;
            right: 10px;
            top: 8px;

            .visually-hidden {
                display: none;
            }

            .rbt-close-content {
                display: none;
            }
        }
    }

    .rbt-menu {
        background-color: rgb(255 255 255);
        border-radius: 8px;
        box-shadow: 0 2px 16px rgb(52 67 74 / 20%);
        opacity: 1;
        padding: 0;
        transform: none;
        z-index: 10;

        .dropdown-item {
            padding: 1rem;
            transition: background-color .3s;
        }

        .rbt-menu-pagination-option {
            color: $primary;
        }
    }
}

input[type="text"]::-webkit-input-placeholder {
    color: $secondary !important;
}

input[type="text"]::placeholder {
    color: $secondary !important;
}

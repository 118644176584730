@import '../../../../styles/variables/colors';
.payment-result {
    .header {
        text-align: center;
        .title {
            font-size: 22px;
            .section-title {
                font-size: 22px;
                .icon {
                    display: block;
                    margin-bottom: 14px;
                    > svg {
                        fill: white;
                        height: 48px;
                        width: 48px;
                        border: 10px solid;
                        border-radius: 60px;
                        g {
                            stroke: white;
                            fill: white;
                        }
                    }
                }
            }
        }
    }
    .body {
        text-align: center;
        margin: 0 14px;
        .actions {
            margin-top: 40px;
        }
    }
}

.payment-result-ko {
    color: $tertiary;
    .title {
        .section-title {
            color: $tertiary;
            .icon {
                > svg {
                    fill: white;
                    border-color:  $tertiary;
                    background: $tertiary;
                    g {
                        stroke: white;
                        fill: white;
                    }
                }

            }
        }
    }
}

.payment-result-ok {
    .title {
        .section-title {
            color: $secondary;
            .icon {
                > svg {
                    fill: $secondary;
                    background: $secondary;
                }
            }
        }
    }
}

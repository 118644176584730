// Responsive typography
html,
main {
    font-size: .95rem;
    min-height: 100vh;
    overflow-x: hidden;

    @include media-breakpoint-up(sm) {
        font-size: .95rem;
    }

    @include media-breakpoint-up(md) {
        font-size: .95rem;
    }

    @include media-breakpoint-up(lg) {
        font-size: 1rem;
    }
}

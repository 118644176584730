@import 'src/styles/bootstrap/bootstrap-grid.scss';
@import 'src/styles/variables/all';
@import 'src/styles/components/all';
@import 'src/styles/reset';

.cart-container {
    min-height: 100vh;
    padding-bottom: 10rem;
    background-color: $white;
    margin-top: 1rem;

    .cart-header {
        margin: 0 8rem;
    }

    @include media-breakpoint-down(lg) {
        padding-bottom: 20rem;

        .cart-header {
            margin: 0 2rem;
        }
    }


    .cart-summary-content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 0 8rem;
        @include media-breakpoint-down(md) {
            flex-direction: column-reverse;

        }

        @include media-breakpoint-down(lg) {
            margin: 0 2rem;
        }

        .cart-content-subtitle {
            color: $medium;
            font-family: 'DM Sans', sans-serif;
            font-weight: $co-font-bold;
            font-size: 18px;
            margin-bottom: .5rem;
        }

        .card-box-summary {
            background-color: rgba($medium, .05);
            padding: 1rem;
            margin-bottom: 1rem;
            display: flex;
            flex-direction: column;

            .card-box-summary-list-container {
                list-style: none;
                padding: 0;
                color: $dark;
                font-size: 1rem;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                gap: .5rem;
                text-align: center;
                margin-bottom: 0;

                .card-box-summary-list-item {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    text-align: left;
                    flex-wrap: wrap;

                    &.cart-discounts {
                        background-color: $light;
                        padding: 10px;
                    }

                    .title, .value {
                        flex: 0 0 auto;
                        width: 50%;
                        font-size: 16px;
                        font-weight: $co-font-bold;
                    }

                    .value {
                        text-align: right;
                    }

                    &.summary-total-price{
                        align-items: baseline;
                        display: flex;
                        margin-top: 2rem;

                        .summary-total-price-title {
                            font-size: 20px;
                            font-weight: $co-font-medium;
                        }

                        .summary-total-price-import {
                            font-size: 32px;
                            font-weight: $co-font-bold;
                        }
                    }
                }
            }
        }

        .shipping-instructions-container {
            display: flex;
            flex-direction: column;

            .order-shipping-address-container {
                display: flex;
                flex-direction: column;
                margin-bottom: 2rem;
                flex: 1;

                .delivery-address {
                    font-size: 16px;
                    margin-bottom: 1rem;
                    font-weight: $co-font-medium;
                }

                .info-message {
                    font-size: 16px;
                    margin-bottom: 1rem;
                    font-weight: $co-font-medium;
                }

                > button {
                    align-self: start;
                }
            }

            .po-container {
                display: flex;
                flex-direction: column;
                margin-bottom: 2rem;
                flex: 1;
            }

            .transport-form-container {
                display: flex;
                flex-direction: column;

                .transport-form-container-item {
                    margin-bottom: 2rem;
                    display: inline-flex;
                    gap: 5px;
                    justify-content: space-between;

                    &__courier, &__courier-service {
                        display: flex;
                        flex-direction: column;
                        margin-bottom: 1rem;
                    }

                    .transport-form-check {
                        margin-left: 1rem;
                        padding-left: inherit;
                    }

                    label, input[type="radio"], input[type="checkbox"] {
                        font-size: 14px;
                        cursor: pointer;
                    }

                    textarea {
                        height: 100px;
                        resize: none;
                        background-color: $light;
                        padding: 1rem;
                    }

                    textarea::placeholder {
                        color: $medium;
                    }
                }
            }
        }
    }

    .cart-summary-footer {
        background-color: $white;
        bottom: 0;
        box-shadow: 0 0 1rem rgba($dark, .2);
        padding: 1rem;
        position: fixed;
        width: 100%;
        z-index: 100;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 1rem;

        @include media-breakpoint-down(lg) {
            display: flex;
            flex-direction: column;
        }

        .cart-summary-footer-price-container{
            display: flex;
            align-items: center;
            gap: 2rem;
            margin: 0 8rem;

            @include media-breakpoint-down(lg) {
                margin: 0;
            }

            .cart-summary-footer-total-price-title{
                color: $primary;
                font-size: 20px;
                font-weight: $co-font-bold;
            }

            .cart-summary-footer-total-price-import {
                color: $dark;
                font-size: 20px;
                font-weight: $co-font-bold;
            }
        }

        .cart-summary-footer-buttons-container{
            display: flex;
            gap: 1rem;
            margin: 0 8rem;

            @include media-breakpoint-down(lg) {
                flex-direction: column;
                gap: 1rem;
                margin: 0;
            }
        }
    }
}


.spinner-container {
    display: flex;
    justify-content: center;
    padding: 1rem 0;
}

// DOWNLOAD
.download-container {
    display: grid;
    // grid-auto-rows: auto-fill;
    grid-auto-flow: dense;
    grid-gap: 2rem;
    grid-template-columns: repeat(auto-fill, minmax(12rem, 4fr));
    list-style: none;
    margin-left: 0;
    padding-left: 0;

    .download-item {
        text-align: center;
        // text-align: right;
        .download-img {
            img {
                height: 10rem;
                object-fit: cover;
                object-fit: cover;
                width: 100%;
            }
        }

        .download-name {
            font-weight: 600;
            margin-top: 1rem;
            // text-align: left;
        }

        .download-date {
            // text-align: left;
            font-size: .75rem;
        }

        .download-size {
            // text-align: left;
            font-size: .75rem;
            // margin-bottom: .5rem;
        }
    }
}

.pagination {
    display: flex;

    @include list-unstyled();
}

.page-link {
    background-color: $pagination-bg;
    border: $pagination-border-width solid $pagination-border-color;
    color: $pagination-color;
    display: block;
    position: relative;
    text-decoration: if($link-decoration == none, null, none);

    @include transition($pagination-transition);

    &:hover {
        background-color: $pagination-hover-bg;
        border-color: $pagination-hover-border-color;
        color: $pagination-hover-color;
        text-decoration: if($link-hover-decoration == underline, none, null);
        z-index: 2;
    }

    &:focus {
        background-color: $pagination-focus-bg;
        box-shadow: $pagination-focus-box-shadow;
        color: $pagination-focus-color;
        outline: $pagination-focus-outline;
        z-index: 3;
    }
}

.page-item {
    &:not(:first-child) .page-link {
        margin-left: $pagination-margin-start;
    }

    &.active .page-link {
        border-color: $pagination-active-border-color;
        color: $pagination-active-color;
        z-index: 3;

        @include gradient-bg($pagination-active-bg);
    }

    &.disabled .page-link {
        background-color: $pagination-disabled-bg;
        border-color: $pagination-disabled-border-color;
        color: $pagination-disabled-color;
        pointer-events: none;
    }
}

//
// Sizing
//
@include pagination-size($pagination-padding-y, $pagination-padding-x, null, $pagination-border-radius);

.pagination-lg {
    @include pagination-size($pagination-padding-y-lg, $pagination-padding-x-lg, $font-size-lg, $pagination-border-radius-lg);
}

.pagination-sm {
    @include pagination-size($pagination-padding-y-sm, $pagination-padding-x-sm, $font-size-sm, $pagination-border-radius-sm);
}

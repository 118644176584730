@import 'src/styles/reset';
@import 'src/styles/variables/all';
@import 'src/styles/components/all';
@import 'src/styles/bootstrap/bootstrap-grid.scss';
@import 'src/styles/bootstrap/bootstrap-reboot.scss';
@import 'src/styles/bootstrap/bootstrap.scss';

.calendar-modal-backdrop-wrapper {
    &.modal-backdrop {
        z-index: 1055;
    }
}


.calendar-selector-body-container {
    display: flex;
    flex-direction: column;

    > p {
        margin-bottom: 2rem;
    }

    .calendar-container {
        width: 100%;
        display: flex;
        justify-content: center;
    }

}

.react-datepicker-wrapper {
    display: none;
}

.react-datepicker__tab-loop {

    .react-datepicker-popper {
        position: relative !important;
        inset: unset !important;
        transform: none !important;
    }
}

.react-datepicker__day{
    background-color: $calendarDayAvailable;
    color: $co-color-white;
    margin: 0.5px;
    font-size: 12px;

    &:hover{
        background-color: $primary;
        border-radius: 0;
    }

}

.react-datepicker__day--selected {
    background-color: $primary;
    border-radius: 0;

    &:hover{
        background-color: $primary;
        border-radius: 0;
    }
}

.react-datepicker__day--disabled {
    background-color: $calendarDayDisabled;
    color: $calendarDayText;

    &:hover{
        background-color: $calendarDayDisabled;
        border-radius: 0;
    }
}

.react-datepicker__triangle{
    display: none;
}

.react-datepicker__header{
    background-color: transparent;
    border: none;
}

.react-datepicker__day-name {
    margin: 10px 0 0 0;
    color: $calendarDayText
}

.react-datepicker__navigation-icon::before {
    border-color: $black;

    &:hover{
        border-color: $black;
    }
}

.react-datepicker__navigation:hover *::before {
    border-color: $primary;
}

.react-datepicker {
    border: none;
}

.react-datepicker__day--keyboard-selected {
    border-radius: 0;
}

@import 'src/styles/reset';
@import 'src/styles/variables/all';
@import 'src/styles/components/all';
@import 'src/styles/bootstrap/bootstrap-grid.scss';
@import 'src/styles/bootstrap/bootstrap-reboot.scss';
@import 'src/styles/bootstrap/bootstrap.scss';


.orders-to-load-body-container {
    display: flex;
    height: 100%;
    flex-direction: row;

    .orders-to-load-container {
        height: 100%;
        width: 50%;
        display: flex;
        flex-direction: column;


        .orders-to-load-instructions-container {
            margin-bottom: 3rem;
        }

        .orders-to-load-shipping-address-container {
            display: flex;
            flex-direction: column;
            margin-bottom: 1rem;

            > p {
                font-weight: $font-weight-bolder;
                font-size: 18px;
            }

            > span {
                font-weight: $font-weight-bold;
                font-size: 14px;
            }
        }

        .orders-to-load-card {
            display: flex;
            flex-direction: column;
            height: 100%;
            padding: .5rem;
            width: 100%;
            overflow-x: auto;

            .orders-to-load-card-header {
                display: flex;
                justify-content: space-between;
                align-items: center;


                .order-to-load-card-header-info-container {
                    display: flex;
                    flex-direction: column;
                    margin-top: 2rem;

                    .order-to-load-card-header-info-item {
                        display: flex;
                        justify-content: space-between;
                        margin-right: 1rem;

                        .order-to-load-card-header-info-item-skeleton{
                            --base-color: #B3B3B3;
                            margin-left: 1rem;
                        }
                        .order-to-load-card-header-info-item-title {
                            font-weight: $font-weight-bold;
                            font-size: 16px;
                        }

                        .order-to-load-card-header-info-item-value {
                            font-weight: $font-weight-bolder;
                            margin-left: 1rem;
                            display: flex;
                            justify-self: flex-end;
                        }
                    }
                }

                > h5 {
                    margin-bottom: 1rem;
                }

            }


            &.background {
                background-color: $loadingOrderCard;
            }
        }
    }
}

@media (max-width: 1700px) {
    .orders-to-load-body-container {
        flex-direction: column;

        .orders-to-load-container {
            width: 100%;
            height: auto;

            .orders-to-load-instructions-container {
                margin-bottom: 0;
            }

            .orders-to-load-card {
                width: auto;
                height: auto;
                margin-bottom: 2rem;
            }
        }
    }
}


$co-color-white: #FFFFFF;
$co-color-black: #000000;

$co-color-gray_light: #F0F0F04D;

$co-color-gray01: #282C34;
$co-color-gray02: #4D4D4DB0;

$co-color-blue01: #61DAFB;

// BS overrides
$primary: #C49D68;
$secondary: #9CA89B;
$tertiary: #7C4C54;
$success: #198754;
$info: #0DCAF0;
$warning: #FFC107;
$danger: #DC3545;
$black: #000000;
$dark: #262626;
$medium: #808080;
$light: #F0F0F0;
$lighter-1: #F1F1F1;
$light-important: #F6F1E9;
$white: #FFFFFF;

$statusLabel: #9CA89B;

$stripe: #F7F7F7;
$loadingOrderCard: #F3F3F3;
$loadingOrderActionButton: #B3B3B3;
$orderLoadedRow: #EBDECC;
$orderItemLoadedRow: $light-important;

$calendarDayText: #B3BAC2;
$calendarDayDisabled: #E5E6E8;
$calendarDayAvailable: $secondary;

$select-item-bg: $lighter-1;
$select-item-selected-bg: $light-important;



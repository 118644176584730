// Base class
//
// Requires one of the contextual, color modifier classes for `color` and
// `background-color`.

.badge {
    color: $badge-color;
    display: inline-block;
    font-weight: $badge-font-weight;
    line-height: 1;
    padding: $badge-padding-y $badge-padding-x;
    text-align: center;
    vertical-align: baseline;
    white-space: nowrap;

    @include font-size($badge-font-size);
    @include border-radius($badge-border-radius);
    @include gradient-bg();

    // Empty badges collapse automatically
    &:empty {
        display: none;
    }
}

// Quick fix for badges in buttons
.btn .badge {
    position: relative;
    top: -1px;
}

.badge-image {
    margin-bottom: .4em;
}

.cart-counter {
    background-color: $primary;
    border-radius: 50%;
    color: $white;
    font-size: 14px;
    height: 20px;
    line-height: 20px;
    position: absolute;
    right: -10px;
    text-align: center;
    top: -10px;
    width: 20px;
}

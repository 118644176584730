.container-weight-error-container {
    display: flex;
    flex-direction: column;

    .container-weight-error-message{
        margin-bottom: 2rem;
    }

    .container-weight-error-quantity-message{
        display: flex;
        gap: 10px;

        .container-weight-error-quantity-title {
            font-weight: bold;
        }
    }
}

@import 'src/styles/variables/all';

.co-app {
    background-color: white;
    color: #262626;
    font-family: 'Jost*', sans-serif;
    line-height: 1.4;
    position: relative;
    transition: .3s;
    z-index: 10;
}

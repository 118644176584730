.modal-content {
    border-radius: 0;
    box-shadow: 0 0 .625rem rgba($dark, .15);
    padding: 1rem;
}

.modal-header,
.modal-footer {
    border: none;
}

.modal-body {
    .detail {
        background-color: $light;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: .5rem;
        margin-bottom: .25rem;
        padding: .5rem;
    }
}

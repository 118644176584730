.account-card {
    background-color: $co-color-gray_light;
    margin-bottom: 3rem;
    padding: 1.25rem;
    width: 100%;

    .account-card-subtitle {
        color: #6C757D;
    }

    .account-card-item {
        margin-bottom: 3rem;

        @include media-breakpoint-up(lg) {
            flex: 0 0 auto;
            width: 50%;
        }
    }

    .account-card-loading-container {
        display: flex;
        justify-content: center;
    }

    .account-card-default-address-container {
        display: flex;
        flex-direction: column;
        margin-bottom: 3rem;
        margin-top: 2rem;

        .default-address-info-actions-container {
            display: flex;
            gap: 1rem;
            align-items: flex-end;

            .default-address-info {
                font-weight: $co-font-medium;
            }

            .default-address-actions {
                display: flex;
                flex: 1;
                margin-left: auto;

                .btn-icon {
                    background: transparent;
                    border: none;
                    cursor: pointer;
                    margin: 0 .5rem;
                }
            }
        }
    }

    .account-card-description-field-container {
        margin-bottom: 2rem;
    }

    .account-card-reset-password-form {
        margin-left: 0;
        margin-right: 0;
        padding: 0;

        .reset-password-button-container {
            display: flex;
            justify-content: end;
            margin-top: 1.5rem;

            .validation-error {
                margin-right: 1rem;
            }
        }
    }

    .account-card-contact-container {
        background-color: #FFFFFF;
        margin-bottom: 1rem;
        padding: 1rem;

        --bs-gutter-x: 1rem;

        .account-card-contact-description {
            margin-bottom: 1rem;

            @include media-breakpoint-up(lg) {
                flex: 0 0 auto;
                width: 100%;
            }
        }

        .account-card-contact-email {
            margin-bottom: 1rem;

            @include media-breakpoint-up(lg) {
                flex: 0 0 auto;
                width: 70%;
            }
        }

        .account-card-contact-phone {
            margin-bottom: 1rem;

            @include media-breakpoint-up(lg) {
                flex: 0 0 auto;
                width: 30%;
            }
        }

        .account-card-contact-notifications-container {
            display: none;
            margin-bottom: 1rem;
            margin-top: 1rem;
        }

        .account-card-contact-delete-icon-container {
            display: flex;
            justify-content: end;
            margin-top: 1rem;

            .btn-icon-delete {
                background-color: transparent;
                border: .0836rem;
                border-radius: 0;
                font-size: 1rem;
                font-weight: 500;
                letter-spacing: .02rem;
                padding: .5rem 1.5rem;
                transition: .15s;
                width: 2rem;
            }
        }
    }

    .actions {
        display: flex;
        justify-content: end;

        .action-add {
            display: flex;
        }
    }

    .add-contact-container {
        display: flex;
        justify-content: end;
        margin-top: 1.5rem;

        .add-contact-item {
            display: flex;
            text-decoration: none;

            svg {
                margin-right: .5rem;
            }
        }
    }

    .shipping-address-container {
        cursor: pointer;
        transition: background-color .1s linear;

        &:hover {
            background-color: $light-important;
        }

        .form-control {
            background: transparent;
        }

        .spinner {
            left: 14px;
            position: absolute;
        }
    }

    .account-card-add-address-button-container {
        display: flex;
        justify-content: end;

        .account-card-add-address-button{
            text-decoration: none;
        }
    }
}

.add-contact-wrapper {
    background-color: transparent;
    margin-bottom: 0;
    padding: 0;
}

.error-input-form {
    color: $red;
    display: block;
    margin-top: 1.5rem;
}

.shipping_address-form-container {
    display: grid;
    gap: .5rem 12px;
    grid-auto-flow: row;
    grid-template-areas: '. .';
    grid-template-columns: auto 1fr;

    .address-field,
    .address1-field,
    .address2-field,
    .description-field,
    .phone-field,
    .contact-field,
    .fax-field, {
        grid-column-end: 3;
        grid-column-start: 1;
        grid-row-end: auto;
    }

    .actions{
        grid-column-end: 3;
        grid-column-start: 1;
        grid-row-end: auto;
        display: flex;
        justify-content: flex-end;
    }
}

.shipping_address-form-error-container {
    color: $red;
    display: block;
    margin-bottom: 1.5rem;
}

.add-shipping-address-button-container {
    display: flex;
    padding: 0;
    justify-content: flex-end;

    .add-shipping-address {
        display: flex;
        padding: 1rem;
        align-items: center;
    }
}

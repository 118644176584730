//
// Labels
//

.form-label {
    color: $form-label-color;
    font-style: $form-label-font-style;
    font-weight: $form-label-font-weight;
    margin-bottom: $form-label-margin-bottom;
    opacity: .5;

    @include font-size($form-label-font-size);
}

// For use with horizontal and inline forms, when you need the label (or legend)
// text to align with the form controls.
.col-form-label {
    color: $form-label-color;
    font-style: $form-label-font-style;
    font-weight: $form-label-font-weight;
    line-height: $input-line-height;
    margin-bottom: 0; // Override the `<legend>` default
    padding-bottom: add($input-padding-y, $input-border-width);
    padding-top: add($input-padding-y, $input-border-width);

    @include font-size(inherit); // Override the `<legend>` default
}

.col-form-label-lg {
    padding-bottom: add($input-padding-y-lg, $input-border-width);
    padding-top: add($input-padding-y-lg, $input-border-width);

    @include font-size($input-font-size-lg);
}

.col-form-label-sm {
    padding-bottom: add($input-padding-y-sm, $input-border-width);
    padding-top: add($input-padding-y-sm, $input-border-width);

    @include font-size($input-font-size-sm);
}

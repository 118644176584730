// Color utilities
.primary-color {
    color: $primary;
}

.secondary-color {
    color: $secondary;
}

.success-color {
    color: $success;
}

.info-color {
    color: $info;
}

.warning-color {
    color: $warning;
}

.danger-color {
    color: $danger;
}

.dark-color {
    color: $dark;
}

.light-color {
    color: $light;
}

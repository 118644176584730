//
// Base styles
//

.btn {
    background-color: transparent;
    border: $btn-border-width solid transparent;
    color: $body-color;
    cursor: if($enable-button-pointers, pointer, null);
    display: inline-block;
    font-family: $btn-font-family;
    font-weight: $btn-font-weight;
    line-height: $btn-line-height;
    text-align: center;
    text-decoration: if($link-decoration == none, null, none);
    user-select: none;
    vertical-align: middle;
    white-space: $btn-white-space;

    @include button-size($btn-padding-y, $btn-padding-x, $btn-font-size, $btn-border-radius);
    @include transition($btn-transition);

    &:focus {
        outline: none;
        box-shadow: none;
    }

    &:active {
        outline: none;
        box-shadow: none;
    }
    &:hover {
        color: $body-color;
        text-decoration: if($link-hover-decoration == underline, none, null);
    }

    .btn-check:focus + &,
    &:focus {
        outline: 0;
    }

    .btn-check:checked + &,
    .btn-check:active + &,
    &:active,
    &.active {
        @include box-shadow($btn-active-box-shadow);

        &:focus {
            @include box-shadow($btn-focus-box-shadow, $btn-active-box-shadow);
        }
    }

    &:disabled,
    &.disabled,
    fieldset:disabled & {
        opacity: $btn-disabled-opacity;
        pointer-events: none;

        @include box-shadow(none);
    }
}

//
// Alternate buttons
//

// scss-docs-start btn-variant-loops
@each $color, $value in $theme-colors {
    .btn-#{$color} {
        @include button-variant($value, $value);
    }
}

@each $color, $value in $theme-colors {
    .btn-outline-#{$color} {
        @include button-outline-variant($value);
    }
}
// scss-docs-end btn-variant-loops

//
// Link buttons
//

// Make a button look and behave like a link
.btn-link {
    border: none;
    color: $btn-link-color;
    font-weight: $font-weight-bold;
    text-decoration: $link-decoration;

    &:hover {
        color: $btn-link-hover-color;
        text-decoration: $link-hover-decoration;
    }

    &:focus {
        text-decoration: $link-hover-decoration;
    }

    &:disabled,
    &.disabled {
        color: $btn-link-disabled-color;
    }

    // No need for an active state here
}

//
// Button Sizes
//

.btn-lg {
    @include button-size($btn-padding-y-lg, $btn-padding-x-lg, $btn-font-size-lg, $btn-border-radius-lg);
}

.btn-sm {
    @include button-size($btn-padding-y-sm, $btn-padding-x-sm, $btn-font-size-sm, $btn-border-radius-sm);
}

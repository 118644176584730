.offcanvas {
    background-clip: padding-box;
    background-color: $offcanvas-bg-color;
    bottom: 0;
    color: $offcanvas-color;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    outline: 0;
    position: fixed;
    visibility: hidden;
    z-index: $zindex-offcanvas;

    @include box-shadow($offcanvas-box-shadow);
    @include transition(transform $offcanvas-transition-duration ease-in-out);
}

.offcanvas-backdrop {
    @include overlay-backdrop($zindex-offcanvas-backdrop, $offcanvas-backdrop-bg, $offcanvas-backdrop-opacity);
}

.offcanvas-header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: $offcanvas-padding-y $offcanvas-padding-x;

    .btn-close {
        margin-bottom: $offcanvas-padding-y * -.5;
        margin-right: $offcanvas-padding-x * -.5;
        margin-top: $offcanvas-padding-y * -.5;
        padding: ($offcanvas-padding-y * .5) ($offcanvas-padding-x * .5);
    }
}

.offcanvas-title {
    line-height: $offcanvas-title-line-height;
    margin-bottom: 0;
}

.offcanvas-body {
    flex-grow: 1;
    overflow-y: unset;
    padding: $offcanvas-padding-y $offcanvas-padding-x;
}

.offcanvas-start {
    border-right: $offcanvas-border-width solid $offcanvas-border-color;
    left: 0;
    top: 0;
    transform: translateX(-100%);
    width: $offcanvas-horizontal-width;
}

.offcanvas-end {
    border-left: $offcanvas-border-width solid $offcanvas-border-color;
    right: 0;
    top: 0;
    transform: translateX(100%);
    width: $offcanvas-horizontal-width;
}

.offcanvas-top {
    border-bottom: $offcanvas-border-width solid $offcanvas-border-color;
    height: $offcanvas-vertical-height;
    left: 0;
    max-height: 100%;
    right: 0;
    top: 0;
    transform: translateY(-100%);
}

.offcanvas-bottom {
    border-top: $offcanvas-border-width solid $offcanvas-border-color;
    height: $offcanvas-vertical-height;
    left: 0;
    max-height: 100%;
    right: 0;
    transform: translateY(100%);
}

.offcanvas.show {
    transform: none;
}

// Base class
.tooltip {
    display: block;
    margin: $tooltip-margin;
    opacity: 0;
    position: absolute;
    // Allow breaking very long words so they don't overflow the tooltip's bounds
    word-wrap: break-word;
    z-index: $zindex-tooltip;
    // Our parent element can be arbitrary since tooltips are by default inserted as a sibling of their target element.
    // So reset our font and text properties to avoid inheriting weird values.
    @include reset-text();
    @include font-size($tooltip-font-size);

    &.show {
        opacity: $tooltip-opacity;
    }

    .tooltip-arrow {
        display: block;
        height: $tooltip-arrow-height;
        position: absolute;
        width: $tooltip-arrow-width;

        &::before {
            border-color: transparent;
            border-style: solid;
            content: '';
            position: absolute;
        }
    }
}

.bs-tooltip-top {
    padding: $tooltip-arrow-height 0;

    .tooltip-arrow {
        bottom: 0;

        &::before {
            border-top-color: $tooltip-arrow-color;
            border-width: $tooltip-arrow-height ($tooltip-arrow-width * .5) 0;
            top: -1px;
        }
    }
}

.bs-tooltip-end {
    padding: 0 $tooltip-arrow-height;

    .tooltip-arrow {
        height: $tooltip-arrow-width;
        left: 0;
        width: $tooltip-arrow-height;

        &::before {
            border-right-color: $tooltip-arrow-color;
            border-width: ($tooltip-arrow-width * .5) $tooltip-arrow-height ($tooltip-arrow-width * .5) 0;
            right: -1px;
        }
    }
}

.bs-tooltip-bottom {
    padding: $tooltip-arrow-height 0;

    .tooltip-arrow {
        top: 0;

        &::before {
            border-bottom-color: $tooltip-arrow-color;
            border-width: 0 ($tooltip-arrow-width * .5) $tooltip-arrow-height;
            bottom: -1px;
        }
    }
}

.bs-tooltip-start {
    padding: 0 $tooltip-arrow-height;

    .tooltip-arrow {
        height: $tooltip-arrow-width;
        right: 0;
        width: $tooltip-arrow-height;

        &::before {
            border-left-color: $tooltip-arrow-color;
            border-width: ($tooltip-arrow-width * .5) 0 ($tooltip-arrow-width * .5) $tooltip-arrow-height;
            left: -1px;
        }
    }
}

.bs-tooltip-auto {
    &[data-popper-placement^='top'] {
        @extend .bs-tooltip-top;
    }

    &[data-popper-placement^='right'] {
        @extend .bs-tooltip-end;
    }

    &[data-popper-placement^='bottom'] {
        @extend .bs-tooltip-bottom;
    }

    &[data-popper-placement^='left'] {
        @extend .bs-tooltip-start;
    }
}

// Wrapper for the tooltip content
.tooltip-inner {
    background-color: $tooltip-bg;
    color: $tooltip-color;
    max-width: $tooltip-max-width;
    padding: $tooltip-padding-y $tooltip-padding-x;
    text-align: center;

    @include border-radius($tooltip-border-radius);
}
